import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { AppBar, Toolbar, IconButton, Typography, Avatar, Drawer, List, ListItem, ListItemText, ListItemIcon, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InfoIcon from '@mui/icons-material/Info';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import logo from "../Assets/bdri-logo.png";
import defaultAvatar from "../Assets/bdri-logo.png";
import '../styles/Nav.css';
import { ENDPOINT } from './config';

export default function Nav() {
  const { isAuthenticated, isLoading, user, loginWithRedirect, logout, getIdTokenClaims } = useAuth0();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [centreName, setCentreName] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (isAuthenticated) {
        try {
          const token = await getIdTokenClaims();
          const response = await axios.get(`${ENDPOINT}/api/user/${user.sub}`, {
            headers: {
              Authorization: `Bearer ${token.__raw}`,
            },
          });
          setCentreName(response.data.centreName);
          setIsAdmin(response.data.isAdmin || false);

          if (!response.data.centreName) {
            navigate('/complete-profile');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [isAuthenticated, getIdTokenClaims, user, navigate]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogin = () => {
    loginWithRedirect({
      prompt: 'login', // Forces fresh login
    }).then(() => {
      if (isAdmin) {
        navigate('/admin');
      } else {
        navigate('/');
      }
    });
  };
  
  const handleSignUp = () => {
    loginWithRedirect({
      screen_hint: 'signup',
      prompt: 'login', // Forces fresh login for signup
      redirectUri: `${window.location.origin}/complete-profile`,
    });
  };
  
  

  const handleLogout = () => {
    // Clear local storage, session storage, and cookies
    localStorage.clear();
    sessionStorage.clear();
  
    // Clear cookies (useful in some cases if app uses cookies)
    document.cookie.split(";").forEach(function(c) {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  
    // Clear Auth0 session (federated logout ensures IDP sessions are cleared too)
    logout({
      returnTo: window.location.origin,
      federated: true,  // ensures Auth0 and identity provider session is cleared
    }).then(() => {
      // After logout, reload to clear everything in the app
      window.location.reload();
    });
  };
  
  
  

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <img src={logo} alt="Birth Defect Registry of India" style={{ height: 60, marginRight: 20 }} />
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Birth Defects Registry of India
        </Typography>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleMenu}>
          <MenuIcon />
        </IconButton>
        <Drawer anchor="right" open={isMenuOpen} onClose={toggleMenu}>
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleMenu}
            onKeyDown={toggleMenu}
          >
            <List>
              <ListItem button component={Link} to="/">
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem button component={Link} to="/dashboard">
                <ListItemIcon><DashboardIcon /></ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
              <ListItem button component={Link} to="/about">
                <ListItemIcon><InfoIcon /></ListItemIcon>
                <ListItemText primary="About Us" />
              </ListItem>
              <ListItem button component={Link} to="/newsletters">
                <ListItemIcon><NewspaperIcon /></ListItemIcon>
                <ListItemText primary="Newsletter" />
              </ListItem>
              <ListItem button component={Link} to="/contact">
                <ListItemIcon><ContactMailIcon /></ListItemIcon>
                <ListItemText primary="Contact Us" />
              </ListItem>
              {isAdmin && (
                <ListItem button component={Link} to="/admin">
                  <ListItemText primary="Admin" />
                </ListItem>
              )}
              {!isAuthenticated ? (
                <div className="auth-buttons">
                  <ListItem button onClick={handleSignUp}>
                    <ListItemText primary="Sign Up" />
                  </ListItem>
                  <ListItem button onClick={handleLogin}>
                    <ListItemText primary="Log In" />
                  </ListItem>
                </div>
              ) : (
                <>
                  <ListItem>
                    <Avatar src={user.picture || defaultAvatar} alt="User Avatar" />
                    <ListItemText primary={centreName} />
                  </ListItem>
                  <ListItem button onClick={handleLogout}>
                    <ListItemText primary="Log Out" />
                  </ListItem>
                </>
              )}
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}
