import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/AdminPage.css';
import { ENDPOINT } from './config';
import { useAuth0 } from '@auth0/auth0-react'; 

const ValidateUsersPage = () => {
  const { user: authUser } = useAuth0();
  const [unvalidatedUsers, setUnvalidatedUsers] = useState([]);

  useEffect(() => {
    const fetchUnvalidatedUsers = async () => {
      try {
        const response = await axios.get(`${ENDPOINT}/api/user/cd/unvalidated`);
        setUnvalidatedUsers(response.data);
      } catch (error) {
        console.error('Error fetching unvalidated users:', error);
      }
    };

    fetchUnvalidatedUsers();
  }, []);

  const handleValidateUser = async (id) => {
    try {
      await axios.patch(`${ENDPOINT}/api/user/validate/${id}`);
      setUnvalidatedUsers(prevData => prevData.filter(user => user._id !== id));
    } catch (error) {
      console.error('Error validating user:', error);
    }
  };

  return (
    <div className="admin-page">
      <h2>Validate Users</h2>
      {unvalidatedUsers.length === 0 ? (
        <p>No unvalidated users available.</p>
      ) : (
        unvalidatedUsers.map(user => (
          <div key={user._id} className="data-item">
            <h3>{user.centreName}</h3>
            <p>Email: {authUser.email}</p>
            <p>Centre Code: {user.centreCode}</p>
            <p>Address: {user.address}</p>
            <button onClick={() => handleValidateUser(user._id)}>Validate User</button>
          </div>
        ))
      )}
    </div>
  );
};

export default ValidateUsersPage;
