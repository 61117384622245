import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar, Pie, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import '../styles/Dashboard.css';
import { ENDPOINT } from './config';


const Dashboard = () => {
  const [formAData, setFormAData] = useState([]);
  const [formBData, setFormBData] = useState([]);
  const [currentChart, setCurrentChart] = useState('liveBirths');
  const [currentForm, setCurrentForm] = useState('formA');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('Fetching data...');
    const fetchData = async () => {
      try {
        const [formAResponse, formBResponse] = await Promise.all([
          axios.get(`${ENDPOINT}/api/formA/forma`),
          axios.get(`${ENDPOINT}/api/formB/formb`),
        ]);
        setFormAData(formAResponse.data);
        setFormBData(formBResponse.data);
        setLoading(false);
        console.log('Data fetched successfully');
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);


  const processFormAData = () => {
    const liveBirths = [];
    const iudStillBirths = [];
    const mtpFollowingAnomalies = [];
    const reportMonths = [];

    formAData.forEach(data => {
      liveBirths.push(data.liveBirth);
      iudStillBirths.push(data.iudStillBirth);
      mtpFollowingAnomalies.push(data.mtpFollowingAnomaly);
      reportMonths.push(data.reportMonthYear);
    });

    return {
      liveBirths,
      iudStillBirths,
      mtpFollowingAnomalies,
      reportMonths
    };
  };

  const processFormBData = () => {
    const birthTypes = [];
    const deliveryMethods = [];
    const motherAges = [];
    const fatherAges = [];
    const anomalies = [];
    const birthWeights = [];

    formBData.forEach(data => {
      birthTypes.push(data.sectionA.birthType);
      deliveryMethods.push(data.sectionA.babyDeliveredAs);
      motherAges.push(data.sectionB.motherAge);
      fatherAges.push(data.sectionB.fatherAge);
      anomalies.push(data.sectionC.anomaly);
      birthWeights.push(data.sectionA.birthWeight);
    });

    const birthTypeCounts = birthTypes.reduce((acc, type) => {
      acc[type] = (acc[type] || 0) + 1;
      return acc;
    }, {});

    const deliveryMethodCounts = deliveryMethods.reduce((acc, method) => {
      acc[method] = (acc[method] || 0) + 1;
      return acc;
    }, {});

    const anomalyCounts = anomalies.reduce((acc, anomaly) => {
      acc[anomaly] = (acc[anomaly] || 0) + 1;
      return acc;
    }, {});

    return {
      birthTypeCounts,
      deliveryMethodCounts,
      motherAges,
      fatherAges,
      anomalyCounts,
      birthWeights
    };
  };

  const {
    liveBirths,
    iudStillBirths,
    mtpFollowingAnomalies,
    reportMonths
  } = processFormAData();

  const {
    birthTypeCounts,
    deliveryMethodCounts,
    motherAges,
    fatherAges,
    anomalyCounts,
    birthWeights
  } = processFormBData();

  const renderFormAChart = () => {
    switch (currentChart) {
      case 'liveBirths':
        return (
          <div className="chart-container">
            <h3>Live Births</h3>
            <Bar
              data={{
                labels: reportMonths,
                datasets: [
                  {
                    label: 'Live Births',
                    data: liveBirths,
                    backgroundColor: 'rgba(75, 192, 192, 0.6)'
                  }
                ]
              }}
            />
          </div>
        );
      case 'iudStillBirths':
        return (
          <div className="chart-container">
            <h3>IUD Still Births</h3>
            <Bar
              data={{
                labels: reportMonths,
                datasets: [
                  {
                    label: 'IUD Still Births',
                    data: iudStillBirths,
                    backgroundColor: 'rgba(255, 99, 132, 0.6)'
                  }
                ]
              }}
            />
          </div>
        );
      case 'mtpFollowingAnomalies':
        return (
          <div className="chart-container">
            <h3>MTP Following Anomalies</h3>
            <Bar
              data={{
                labels: reportMonths,
                datasets: [
                  {
                    label: 'MTP Following Anomalies',
                    data: mtpFollowingAnomalies,
                    backgroundColor: 'rgba(54, 162, 235, 0.6)'
                  }
                ]
              }}
            />
          </div>
        );
      case 'overallData':
        return (
          <div className="chart-container">
            <h3>Overall Data</h3>
            <Line
              data={{
                labels: reportMonths,
                datasets: [
                  {
                    label: 'Live Births',
                    data: liveBirths,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    fill: false
                  },
                  {
                    label: 'IUD Still Births',
                    data: iudStillBirths,
                    borderColor: 'rgba(255, 99, 132, 1)',
                    fill: false
                  },
                  {
                    label: 'MTP Following Anomalies',
                    data: mtpFollowingAnomalies,
                    borderColor: 'rgba(54, 162, 235, 1)',
                    fill: false
                  }
                ]
              }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const renderFormBChart = () => {
    switch (currentChart) {
      case 'birthTypes':
        return (
          <div className="chart-container">
            <h3>Birth Types Distribution</h3>
            <Pie
              data={{
                labels: Object.keys(birthTypeCounts),
                datasets: [
                  {
                    data: Object.values(birthTypeCounts),
                    backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)']
                  }
                ]
              }}
            />
          </div>
        );
      case 'deliveryMethods':
        return (
          <div className="chart-container">
            <h3>Delivery Methods Distribution</h3>
            <Pie
              data={{
                labels: Object.keys(deliveryMethodCounts),
                datasets: [
                  {
                    data: Object.values(deliveryMethodCounts),
                    backgroundColor: ['rgba(153, 102, 255, 0.6)', 'rgba(255, 159, 64, 0.6)', 'rgba(255, 205, 86, 0.6)']
                  }
                ]
              }}
            />
          </div>
        );
      case 'motherAges':
        return (
          <div className="chart-container">
            <h3>Mother's Age Distribution</h3>
            <Bar
              data={{
                labels: motherAges,
                datasets: [
                  {
                    label: 'Mother\'s Age',
                    data: motherAges,
                    backgroundColor: 'rgba(255, 99, 132, 0.6)'
                  }
                ]
              }}
            />
          </div>
        );
      case 'fatherAges':
        return (
          <div className="chart-container">
            <h3>Father's Age Distribution</h3>
            <Bar
              data={{
                labels: fatherAges,
                datasets: [
                  {
                    label: 'Father\'s Age',
                    data: fatherAges,
                    backgroundColor: 'rgba(54, 162, 235, 0.6)'
                  }
                ]
              }}
            />
          </div>
        );
      case 'anomalies':
        return (
          <div className="chart-container">
            <h3>Anomaly Diagnosis Count</h3>
            <Pie
              data={{
                labels: Object.keys(anomalyCounts),
                datasets: [
                  {
                    data: Object.values(anomalyCounts),
                    backgroundColor: ['rgba(255, 205, 86, 0.6)', 'rgba(153, 102, 255, 0.6)', 'rgba(75, 192, 192, 0.6)']
                  }
                ]
              }}
            />
          </div>
        );
      case 'birthWeights':
        return (
          <div className="chart-container">
            <h3>Birth Weight Distribution</h3>
            <Line
              data={{
                labels: birthWeights.map((_, index) => `Child ${index + 1}`), // Assuming each weight is from a different child
                datasets: [
                  {
                    label: 'Birth Weight (kg)',
                    data: birthWeights,
                    fill: false,
                    borderColor: 'rgba(75, 192, 192, 1)'
                  }
                ]
              }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const renderChart = () => {
    if (currentForm === 'formA') {
      return renderFormAChart();
    } else {
      return renderFormBChart();
    }
  };

  return (
    <div className="dashboard-container">
      <h2>Dashboard</h2>
      {loading ? (
        <div className="loader">Loading...</div>
      ) : (
        <>
          <div className="button-container">
            <button onClick={() => setCurrentForm('formA')}>Form A</button>
            <button onClick={() => setCurrentForm('formB')}>Form B</button>
          </div>
          <div className="button-container">
            {currentForm === 'formA' ? (
              <>
                <button onClick={() => setCurrentChart('liveBirths')}>Live Births</button>
                <button onClick={() => setCurrentChart('iudStillBirths')}>IUD Still Births</button>
                <button onClick={() => setCurrentChart('mtpFollowingAnomalies')}>MTP Following Anomalies</button>
                <button onClick={() => setCurrentChart('overallData')}>Overall Data</button>
              </>
            ) : (
              <>
                <button onClick={() => setCurrentChart('birthTypes')}>Birth Types</button>
                <button onClick={() => setCurrentChart('deliveryMethods')}>Delivery Methods</button>
                <button onClick={() => setCurrentChart('motherAges')}>Mother's Age</button>
                <button onClick={() => setCurrentChart('fatherAges')}>Father's Age</button>
                <button onClick={() => setCurrentChart('anomalies')}>Anomalies</button>
                <button onClick={() => setCurrentChart('birthWeights')}>Birth Weights</button>
              </>
            )}
          </div>
          {renderChart()}
        </>
      )}
    </div>
  );
};

export default Dashboard;
