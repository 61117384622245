import React from 'react';
import '../styles/Section.css';
import { ENDPOINT } from './config';

const SectionC = ({ formData, setFormData }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

  return (
    <div className="form-container">
        <h1>Section C: Diagnosis</h1>
        <div className='form'>
            <div className="form-group">
                <label htmlFor="anomaly">Anomaly *</label>
                <input type="text" id="anomaly" name="anomaly" value={formData.anomaly || ''} onChange={handleChange} />
            </div>
            <div className="form-group">
                <label htmlFor="notifyingPerson">Notifying Person *</label>
                <input type="text" id="notifyingPerson" name="notifyingPerson" value={formData.notifyingPerson || ''} onChange={handleChange} />
            </div>
            <div className="form-group">
                <label htmlFor="designation">Your Designation *</label>
                <input type="text" id="designation" name="designation" value={formData.designation || ''} onChange={handleChange}/>
            </div>
            <div className="form-group">
                <label htmlFor="diagnosis">Provisional Diagnosis *</label>
                <textarea id="diagnosis" name="diagnosis" value={formData.diagnosis || ''} onChange={handleChange}></textarea>
            </div>
        </div>
    </div>
  );
};

export default SectionC;
