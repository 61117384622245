import React, { useState } from 'react';
import '../styles/Section.css';
import { ENDPOINT } from './config';

const SectionA = ({ formData, setFormData }) => {

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <div className="form-container">
      <form className="form">
        <h1>Section A: Identification Details</h1>
        <div className="form-group">
          <label>Date of Birth *</label>
          <input type="date" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Report Month Year *</label>
          <input type="month" name="reportMonthYear" value={formData.reportMonthYear} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Birth Weight (in Kgs) *</label>
          <input type="number" name="birthWeight" value={formData.birthWeight} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Gestational Age (GA) *</label>
          <input type="text" name="gestationalAge" value={formData.gestationalAge} onChange={handleChange} placeholder="Gestational Age (GA)" />
        </div>
        <div className="form-group">
          <label>Baby Delivered as *</label>
          <select name="babyDeliveredAs" value={formData.babyDeliveredAs} onChange={handleChange}>
            <option value="">Select</option>
            <option value="normal">Normal</option>
            <option value="csection">C-section</option>
            {/* Add more options as needed */}
          </select>
        </div>
        <div className="form-group">
          <label>Last Menstrual Period (LMP)</label>
          <input type="date" name="lastMenstrualPeriod" value={formData.lastMenstrualPeriod} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Birth Type *</label>
          <select name="birthType" value={formData.birthType} onChange={handleChange}>
            <option value="">Select</option>
            <option value="single">Single</option>
            <option value="twin">Twin</option>
            <option value="triplet">Triplet</option>
            {/* Add more options as needed */}
          </select>
        </div>
      </form>
    </div>
  );
};

export default SectionA;
