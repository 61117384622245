import React from 'react';
import '../styles/FormsMain.css';
import { Link } from 'react-router-dom';
import { ENDPOINT } from './config';

const Forms = () => {
  return (
    <div className="forms-page">
      <main className="forms-main">
        <h2 className="forms-title">Select a Form</h2>
        <div className="cards-container">

          <Link to="/formA" className="nav-link">
            <div className="card">
              <h3>Form A</h3>
              <p>Enter data for Form A</p>
            </div>
          </Link>
          
          <Link to="/formB" className="nav-link">
            <div className="card">
              <h3>Form B</h3>
              <p>Enter data for Form B</p>
            </div>
          </Link>

          <Link to="/formC" className="nav-link">
            <div className="card">
              <h3>Form C</h3>
              <p>Enter data for Form B</p>
            </div>
          </Link>
        </div>

        <section className="faq-section">
          <h2>Frequently Asked Questions</h2>
          <div className="faq">
            <h3>What is the purpose of these forms?</h3>
            <p>The forms help us collect data on birth defects to monitor trends, evaluate prevention efforts, and connect families with appropriate services.</p>
          </div>
          <div className="faq">
            <h3>How is my data used?</h3>
            <p>Your data is used to improve public health responses and support scientific research into birth defects.</p>
          </div>
          <div className="faq">
            <h3>Is my data confidential?</h3>
            <p>Yes, all data collected is kept confidential and used solely for public health purposes.</p>
          </div>
        </section>
        <section className="contact-query">
          <h2>Need Help?</h2>
          <p>If you have any questions or need assistance, please contact our support team.</p>
          <p>Email: support@bdri.org | Phone: (+91) 00-000-0000</p>
        </section>

      </main>
    </div>
  );
};

export default Forms;