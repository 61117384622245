import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/Newsletters.css';
import { ENDPOINT } from './config';

const Newsletters = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(`${ENDPOINT}/api/news`);
        const articles = response.data;

        const summarizedArticles = await Promise.all(articles.map(async (article) => {
          try {
            const summaryResponse = await axios.post(`${ENDPOINT}/api/summarize`, { text: article.description });
            return { ...article, summary: summaryResponse.data.summary };
          } catch (error) {
            return { ...article, summary: 'Summary not available due to an error.' };
          }
        }));

        setNews(summarizedArticles);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching news:', error);
        setLoading(false);
      }
      
    //   Testing
    //   const firstArticle = articles[0];
    //     let summarizedArticle;
        
    //     try {
    //       const summaryResponse = await axios.post('http://localhost:5000/api/summarize', { text: firstArticle.description });
    //       summarizedArticle = { ...firstArticle, summary: summaryResponse.data.summary };
    //     } catch (error) {
    //       summarizedArticle = { ...firstArticle, summary: 'Summary not available due to an error.' };
    //     }

    //     setNews([summarizedArticle]);
    //     setLoading(false);
    //   } catch (error) {
    //     console.error('Error fetching news:', error);
    //     setLoading(false);
    //   }
    };

    fetchNews();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="newsletters-container">
      <h2>Health and Birth Defect News</h2>
      <div className='news-flex'>
        {news.map((article, index) => (
          <div key={index} className="article">
            <h3>{article.title}</h3>
            <p><strong>Summary:</strong> {article.summary}</p>
            <p><a href={article.url} target="_blank" rel="noopener noreferrer">Read full article</a></p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Newsletters;
