import React, { useState, useEffect } from 'react';
import '../styles/FormA.css';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { ENDPOINT } from './config';
import * as XLSX from 'xlsx'; // Import XLSX library

function FormA() {
  const { isAuthenticated, getIdTokenClaims, user } = useAuth0();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isExcelUploaded, setIsExcelUploaded] = useState(false); // Track if Excel is uploaded
  const [formData, setFormData] = useState({
    reportMonthYear: '',
    liveBirth: 0,
    iudStillBirth: 0,
    mtpFollowingAnomaly: 0,
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (isAuthenticated && user) {
        try {
          const token = await getIdTokenClaims();
          const response = await axios.get(`${ENDPOINT}/api/user/${user.sub}`, {
            headers: {
              Authorization: `Bearer ${token.__raw}`,
            },
          });
          setIsAdmin(response.data.isAdmin || false);

          if (!response.data.centreName) {
            navigate('/complete-profile');
          } else {
            setFormData((prevFormData) => ({
              ...prevFormData,
            }));
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [isAuthenticated, user, getIdTokenClaims, navigate]);

  const handleChange = (e) => {
    if (!isExcelUploaded) {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const headers = parsedData[0];
      const values = parsedData[1]; 

      const newFormData = {};
      headers.forEach((header, index) => {
        newFormData[header] = String(values[index]); 
      });

      console.log(newFormData);

      setFormData(newFormData);

      setIsExcelUploaded(true); 
    };

    reader.readAsArrayBuffer(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      console.log(formData);
      const response = await axios.post(`${ENDPOINT}/api/formA/cd`, formData);
      console.log(response.data);
      setSuccess(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-page">
      {loading && <div className="loading">Submitting...</div>}
      {success && <div className="success-popup">Form submitted successfully!</div>}
      {!loading && !success && (
        <>
          <section className="form-intro-section">
            <h2>Monthly Birth and Pregnancy Report Form</h2>
            <p>This form is designed to collect monthly data related to births and pregnancy outcomes. You can either upload an Excel file with the required data or enter the details manually.</p>
          </section>

          {/* Excel Upload Section */}
          <section className="upload-section">
            <h3>Upload Excel File</h3>
            <p>You can upload an Excel file to populate the form automatically. Ensure the file contains the required data in the correct format.</p>
            <div className="form-group-A">
              <label>Upload Excel File (optional)</label>
              <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
            </div>
            {isExcelUploaded && <p className="success-message">Excel file uploaded successfully. The form has been populated.</p>}
          </section>

          <div className="divider">OR</div> {/* Divider between sections */}

          {/* Manual Entry Section */}
          <section className="manual-entry-section">
            <h3>Manual Data Entry</h3>
            <p>Alternatively, you can enter the data manually if you prefer.</p>

            <form className="form-A" onSubmit={handleSubmit}>
              <div className="form-group-A">
                <label>Report Month Year *</label>
                <input
                  type="date"
                  name="reportMonthYear"
                  value={formData.reportMonthYear}
                  onChange={handleChange}
                  required
                  disabled={isExcelUploaded} // Disable if Excel is uploaded
                />
              </div>
              <div className="form-group-A">
                <label>Live Birth *</label>
                <input
                  type="number"
                  name="liveBirth"
                  value={formData.liveBirth}
                  onChange={handleChange}
                  required
                  disabled={isExcelUploaded} // Disable if Excel is uploaded
                />
              </div>
              <div className="form-group-A">
                <label>IUD Still Birth *</label>
                <input
                  type="number"
                  name="iudStillBirth"
                  value={formData.iudStillBirth}
                  onChange={handleChange}
                  required
                  disabled={isExcelUploaded} // Disable if Excel is uploaded
                />
              </div>
              <div className="form-group-A">
                <label>MTP Following Anomaly *</label>
                <input
                  type="number"
                  name="mtpFollowingAnomaly"
                  value={formData.mtpFollowingAnomaly}
                  onChange={handleChange}
                  required
                  disabled={isExcelUploaded} // Disable if Excel is uploaded
                />
              </div>

              <div className="form-buttons">
                <Link to="/forms">
                  <button type="button" className="cancel-button">Cancel</button>
                </Link>
                <button type="submit" className="submit-button">Submit</button>
              </div>
            </form>
          </section>
        </>
      )}
    </div>
  );
}

export default FormA;
