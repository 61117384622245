import React from 'react';
import '../styles/Section.css';
import { ENDPOINT } from './config';

const SectionD = ({ formData, setFormData, setFile }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div className="form-container">
      <h1>Section D: Upload Documents</h1>
      <div className='form'>
        <p>
          <b>Note:</b> Relevant USG images / autopsy images - photographs / any others (10 nos. - max) reports / postnatal X-rays for skeletal dysplasias / clinical photographs / any others (10 nos. - max)
        </p>
        <div className="form-group">
          <label htmlFor="document">Document <span className="file-note">Only .pdf files</span></label>
          <input type="file" id="document" name="document" accept=".pdf" onChange={handleFileChange} />
        </div>
        <div className="registry-section">
          <div className="form-group">
            <label htmlFor="finalDiagnosis">Final Diagnosis</label>
            <input type="text" id="finalDiagnosis" name="finalDiagnosis" value={formData.finalDiagnosis} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="icdCode">ICD Code</label>
            <input type="text" id="icdCode" name="icdCode" value={formData.icdCode} onChange={handleChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionD;
