import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Button, Grid } from '@mui/material';
import '../styles/AdminDashboard.css';

const AdminDashboard = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md" className="admin-dashboard" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Admin Dashboard
      </Typography>
      <Box className="form-options" sx={{ mt: 4 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={3}>
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth 
              onClick={() => navigate('/admin/validate/formA')}
              sx={{ height: '100%' }}
            >
              Validate Form A
            </Button>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth 
              onClick={() => navigate('/admin/validate/formB')}
              sx={{ height: '100%' }}
            >
              Validate Form B
            </Button>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth 
              onClick={() => navigate('/admin/validate/formC')}
              sx={{ height: '100%' }}
            >
              Validate Form C
            </Button>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button 
              variant="contained" 
              color="secondary" 
              fullWidth 
              onClick={() => navigate('/admin/validate-users')}
              sx={{ height: '100%' }}
            >
              Validate New Users
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AdminDashboard;
