import React, { useState } from 'react';
import '../styles/Section.css';
import { ENDPOINT } from './config';

const SectionB = ({ formData, setFormData }) => {
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <div className="form-container">
      <h1>
      Section B: Demographic Details
      </h1>
      <form className="form">
        <div className="form-group">
          <label>Birth Type *</label>
          <select name="birthType" value={formData.birthType} onChange={handleChange}>
            <option value="">Select</option>
            <option value="single">Single</option>
            <option value="twin">Twin</option>
            <option value="triplet">Triplet</option>
            {/* Add more options as needed */}
          </select>
        </div>
        <div className="form-group">
          <label>Mother's Name *</label>
          <input type="text" name="motherName" value={formData.motherName} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Mother's Age *</label>
          <input type="number" name="motherAge" value={formData.motherAge} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Father's Name *</label>
          <input type="text" name="fatherName" value={formData.fatherName} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Father's Age *</label>
          <input type="number" name="fatherAge" value={formData.fatherAge} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>House No. / Name *</label>
          <input type="text" name="houseName" value={formData.houseName} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Area / Locality *</label>
          <input type="text" name="area" value={formData.area} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>City / Town *</label>
          <input type="text" name="city" value={formData.city} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>State *</label>
          <input type="text" name="state" value={formData.state} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Pincode *</label>
          <input type="text" name="pincode" value={formData.pincode} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Street *</label>
          <input type="text" name="street" value={formData.street} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Contact Number *</label>
          <input type="text" name="contactNumber" value={formData.contactNumber} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Email Id *</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} />
        </div>
      </form>
        
    </div>
  );
};

export default SectionB;
