import React from 'react'
import '../styles/App.css';
import { ENDPOINT } from './config';

export default function Footer() {
  return (
    <footer className="footer">
        <p>&copy; 2024 Birth Defect Registry of India. All rights reserved.</p>
    </footer>
  )
}
