import React from 'react';
import '../styles/DownloadApp.css';
import appStoreLogo from "../Assets/app-store-logo.png";
import googlePlayLogo from "../Assets/google-play-logo.png";

const DownloadApp = () => {
  return (
    <div className="download-app-section">
      <div className="download-app-content">
        <h2>Download Our App Now</h2>
        <p>Experience the best of our services on the go. Download our app from the App Store or Google Play.</p>
        <div className="download-buttons">
          <a href="https://www.apple.com/app-store/" className="download-button">
            <img src={appStoreLogo} alt="App Store" />
          </a>
          <a href="https://play.google.com/store" className="download-button">
            <img src={googlePlayLogo} alt="Google Play" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
