import React, { useState } from 'react';
import SectionA from "./SectionA.jsx";
import SectionB from "./SectionB.jsx";
import SectionC from "./SectionC.jsx";
import SectionD from "./SectionD.jsx";
import { ENDPOINT } from './config';
import axios from 'axios';
import '../styles/FormB.css';

const FormB = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const [formData, setFormData] = useState({
    sectionA: {},
    sectionB: {},
    sectionC: {},
    sectionD: {},
  });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const sections = [
    { id: 'sectionA', title: 'Section A: Identification Details', component: <SectionA formData={formData.sectionA} setFormData={(data) => setFormData({ ...formData, sectionA: data })} /> },
    { id: 'sectionB', title: 'Section B: Demographic Details', component: <SectionB formData={formData.sectionB} setFormData={(data) => setFormData({ ...formData, sectionB: data })} /> },
    { id: 'sectionC', title: 'Section C: Diagnosis', component: <SectionC formData={formData.sectionC} setFormData={(data) => setFormData({ ...formData, sectionC: data })} /> },
    { id: 'sectionD', title: 'Section D: Upload Documents', component: <SectionD formData={formData.sectionD} setFormData={(data) => setFormData({ ...formData, sectionD: data })} setFile={setFile} /> }
  ];

  const nextStep = () => {
    setCurrentSection(prevSection => Math.min(prevSection + 1, sections.length - 1));
  };

  const prevStep = () => {
    setCurrentSection(prevSection => Math.max(prevSection - 1, 0));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = new FormData();
    data.append('sectionA', JSON.stringify(formData.sectionA));
    data.append('sectionB', JSON.stringify(formData.sectionB));
    data.append('sectionC', JSON.stringify(formData.sectionC));
    data.append('sectionD', JSON.stringify(formData.sectionD));
    if (file) {
      data.append('document', file);
    }

    try {
      const response = await axios.post(`${ENDPOINT}/api/formB`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response.data);
      setSuccess(true);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-formB">
      <h2>Form B</h2>
      {loading && <div className="loading">Submitting...</div>}
      {success && <div className="success-popup">Form submitted successfully!</div>}
      {!loading && !success && (
        <>
          <div className="progress-bar">
            {sections.map((section, index) => (
              <div key={section.id} className={`progress-step ${index <= currentSection ? 'completed' : ''}`}></div>
            ))}
          </div>
          <div className="sections-formB">
            {sections.map((section, index) => (
              <button
                key={section.id}
                className={`section-button ${index === currentSection ? 'active' : ''}`}
                onClick={() => setCurrentSection(index)}
              >
                {section.title}
              </button>
            ))}
          </div>
          <div className="form-section">
            {sections[currentSection].component}
          </div>
          <div className="navigation-buttons">
            <button onClick={prevStep} disabled={currentSection === 0}>Back</button>
            {currentSection === sections.length - 1 ? (
              <button onClick={handleSubmit}>Submit</button>
            ) : (
              <button onClick={nextStep}>Next</button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FormB;
