import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Typography, Button, Grid, TextField } from '@mui/material';
import logo from "../Assets/bdri-logo.png";
import DownloadApp from '../Widgets/DownloadApp';
import 'animate.css';
import '../styles/App.css';
import { ENDPOINT } from './config';

function Home() {
  const { isAuthenticated, loginWithRedirect, user, getIdTokenClaims } = useAuth0();
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isVerified, setIsVerified] = useState(true); // Track if the user is verified
  const [showValidationPopup, setShowValidationPopup] = useState(false);
  const [redirectLink, setRedirectLink] = useState("/");

  useEffect(() => {
    const fetchUserValidation = async () => {
      if (isAuthenticated) {
        try {
          const token = await getIdTokenClaims();
          const response = await axios.get(`${ENDPOINT}/api/user/${user.sub}`, {
            headers: {
              Authorization: `Bearer ${token.__raw}`,
            },
          });
          const verifiedStatus = response.data.isVerified;
          setIsVerified(verifiedStatus); // Update isVerified based on API response
          if (!verifiedStatus) {
            setShowValidationPopup(true); // Show popup if user is not verified
          }
        } catch (error) {
          console.error('Error fetching user validation:', error);
        }
      }
    };
    fetchUserValidation();
  }, [isAuthenticated, getIdTokenClaims, user]);

  const handleRegisterClick = () => {
    if (!isAuthenticated) {
      setShowLoginPopup(true);
    } else {
      if (!isVerified) {
        setShowValidationPopup(true);
      } else {
        setRedirectLink("/forms");
      }
    }
  };

  const closePopup = () => {
    setShowLoginPopup(false);
    setShowValidationPopup(false);
  };

  const handleLogin = () => {
    setShowLoginPopup(false);
    loginWithRedirect();
  };

  return (
    <div className="main-page">
      <section className="hero-section">
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6} className="hero-content">
              <Typography variant="h2" className="title animate__animated animate__fadeInLeft">
                Birth Defects Registry of India
              </Typography>
              <Typography variant="body1" className="description animate__animated animate__fadeInLeft animate__delay-1s">
                        BDRI is the incorporated unit of
                        FCRF, founded in the year 2001, to document the incidences
                        of birth defects in India. The estimated baseline prevalence
                        of birth defects would facilitate the concerned authorities
                        evolve appropriate strategies to prevent birth defects
                        in the nation.
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    className="register-button animate__animated animate__fadeInUp animate__delay-2s"
                    onClick={handleRegisterClick}
                  >
                    {redirectLink && (
                      <Link className="Go-to-forms" to={redirectLink}>Go to Forms</Link>
                    )}
                  </Button>
                </Grid>
                <Grid item>
                  <Link to="/hotspot">
                    <Button
                      variant="contained"
                      color="primary"
                      className="register-button animate__animated animate__fadeInUp animate__delay-2s"
                    >
                      HeatMap
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10} md={4} className="hero-image">
              <img src={logo} alt="Birth Defect Registry of India" className="logo-image animate__animated animate__zoomIn" />
            </Grid>
          </Grid>
        </Container>
      </section>
      
      <section id="about" className="about-section">
        <Container>
          <Typography variant="h4" className="section-title">About Us</Typography>
            <div className='about-text'>
            The Birth Defects Registry of India (BDRI) aims to collect, analyze, and report data on birth defects across India. 
            Our mission is to improve the understanding of birth defects and their prevention.
            </div>
        </Container>
      </section>

      {/* Validation Popup with Hourglass */}
      {showValidationPopup && (
        <div className="validation-popup">
          <div className="popup-content animate__animated animate__fadeIn">
            <div className="hourglass"></div> {/* Hourglass animation */}
            <Typography variant="h6" className="popup-title">Your Account is Not Yet Verified</Typography>
            <Typography variant="body1">Kindly wait until the admin validates your account. If you need assistance, please contact us at 123-456-7890.</Typography>
            <Button variant="outlined" onClick={closePopup}>Close</Button>
          </div>
        </div>
      )}

    <section id="features" className="features-section">
      <Container>
        <Typography variant="h4" className="section-title">Features</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <div className="feature-card">
              <Typography variant="h5" className="feature-title">User-Friendly Forms</Typography>
              <Typography variant="body1" className="feature-description">
                Our application provides intuitive and easy-to-use forms for inputting birth defect data, ensuring accurate and quick data submission.
              </Typography>
            </div>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <div className="feature-card">
              <Typography variant="h5" className="feature-title">Excel Data Upload</Typography>
              <Typography variant="body1" className="feature-description">
                Upload data directly from Excel files to simplify the data entry process, allowing hospitals to streamline their reporting.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <div className="feature-card">
              <Typography variant="h5" className="feature-title">Data Validation</Typography>
              <Typography variant="body1" className="feature-description">
                Built-in admin tools to review and validate data submissions, ensuring accuracy and completeness before final reporting.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <div className="feature-card">
              <Typography variant="h5" className="feature-title">Comprehensive Analytics</Typography>
              <Typography variant="body1" className="feature-description">
                Leverage powerful analytics to identify trends in birth defects across various regions and demographics.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <div className="feature-card">
              <Typography variant="h5" className="feature-title">Heatmap Visualization</Typography>
              <Typography variant="body1" className="feature-description">
                Visualize birth defect data on an interactive heatmap, enabling geographic insights for healthcare providers and authorities.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <div className="feature-card">
              <Typography variant="h5" className="feature-title">Secure Data Management</Typography>
              <Typography variant="body1" className="feature-description">
                Protect sensitive patient information with robust security measures, including role-based access and data encryption.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>


      <DownloadApp />

      <section id="contact" className="contact-section">
        <Container>
          <Typography variant="h4" className="section-title">Contact Us</Typography>
          <form className="contact-form">
            <TextField label="Your Name" variant="outlined" fullWidth margin="normal" required />
            <TextField label="Your Email" variant="outlined" fullWidth margin="normal" required />
            <TextField label="Your Message" variant="outlined" fullWidth multiline rows={4} margin="normal" required />
            <Button variant="contained" color="primary" type="submit" className="contact-button">Send Message</Button>
          </form>
        </Container>
      </section>

      {showLoginPopup && (
        <div className="login-popup">
          <div className="popup-content">
            <Typography variant="h6">Please Log In</Typography>
            <Typography variant="body1">You need to be logged in to register your hospital.</Typography>
            <Button variant="contained" color="primary" className="login-button" onClick={handleLogin}>Log In</Button>
            <Button variant="outlined" className="close-button" onClick={closePopup}>Close</Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
