import React from 'react';
import '../styles/About.css';

const About = () => {
  return (
    <div className="about-us-container">
      <h2>About Birth Defect Registry of India</h2>
      <p>
        The Birth Defect Registry of India (BDRI) is dedicated to monitoring and analyzing birth defects across the country. Our mission is to collect, manage, and interpret data to improve health outcomes for newborns and their families.
      </p>
      
      <div className="stats-container">
        <div className="stat-box">
          <h3>10,000+</h3>
          <p>Registered Birth Defects</p>
        </div>
        <div className="stat-box">
          <h3>5,000+</h3>
          <p>Hospitals Participating</p>
        </div>
        <div className="stat-box">
          <h3>20,000+</h3>
          <p>Successful Interventions</p>
        </div>
      </div>
      
      <div className="mission-container">
        <h3>Our Mission</h3>
        <p>
          Our mission is to reduce the incidence of birth defects and improve the quality of life for affected individuals and their families through data-driven insights, research, and advocacy.
        </p>
      </div>
      
      <div className="contact-container">
        <h3>Contact Us</h3>
        <p>Email: support@bdri.org</p>
        <p>Phone: +91 93802 22773</p>
        <p>Address: No 18, Besant Road, Royapettah, Chennai</p>
      </div>
    </div>
  );
};

export default About;
