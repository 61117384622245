import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Circle, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '../styles/Hotspot.css';
import { ENDPOINT } from './config';

function Hotspot() {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${ENDPOINT}/api/locations`);
        const data = await response.json();
        setLocations(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const renderHeatmap = () => {
    <h1>HeatMap</h1>
    return locations.map((location, index) => (
      <Circle
        key={index}
        center={[location.lat, location.lng]}
        radius={location.intensity * 1000}
        color="red"
        fillOpacity={0.5}
      >
        <Tooltip direction="top" offset={[0, -10]} opacity={1}>
          <span>{location.name}: {location.intensity} Birth Defects registered</span>
        </Tooltip>
      </Circle>
    ));
  };

  return (
    <div className="heatmap-container">
      <h2>Birth Defects Hotspot Heatmap</h2>
      <MapContainer center={[20.5937, 78.9629]} zoom={5} className="map">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {renderHeatmap()}
      </MapContainer>
    </div>
  );
}

export default Hotspot;
