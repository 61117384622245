import React, { useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import '../styles/CompleteProfile.css';
import { ENDPOINT } from './config';


const CompleteProfile = () => {
  const { user, getIdTokenClaims } = useAuth0();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    centreName: '',
    pincode: '',
    address: '',
    isAdmin: 'false',
    isVerified: 'false'
  });
  const [centreCode, setCentreCode] = useState(''); // Centre code generated by the system
  const [showCentreCode, setShowCentreCode] = useState(false); // Show the generated code

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await getIdTokenClaims();

      // Fetch the current count of users to determine the unique prefix (P1, P2, etc.)
      const userCountResponse = await axios.get(`${ENDPOINT}/api/user/cd/count`);
      const userCount = userCountResponse.data.count;

      // Generate unique Centre Code with the format P{index}{pincode}{centreName}
      const newCentreCode = `P${userCount + 1}${formData.pincode}`;
      setCentreCode(newCentreCode); // Set the generated centre code

      // Submit the form data with the generated centre code
      await axios.post(`${ENDPOINT}/api/user`, {
        ...formData,
        centreCode: newCentreCode,
        auth0Id: user.sub,
        token: token.__raw,
      });

      // After successful submission, navigate to dashboard
      navigate('/');
    } catch (error) {
      console.error('Error saving user data:', error);
    }
  };

  return (
    <div className="complete-profile-container">
      <h2>Complete Your Profile</h2>
      {!showCentreCode ? (
        <form className="complete-profile-form" onSubmit={handleSubmit}>
          <div className="complete-profile-form-group">
            <label htmlFor="centreName">Centre Name</label>
            <input
              type="text"
              name="centreName"
              value={formData.centreName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="complete-profile-form-group">
            <label htmlFor="pincode">Pincode</label>
            <input
              type="text"
              name="pincode"
              value={formData.pincode}
              onChange={handleChange}
              required
            />
          </div>
          <div className="complete-profile-form-group">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="complete-profile-submit-button">Submit</button>
        </form>
      ) : (
        <div className="centre-code-prompt">
          <h3>Your Centre Code has been generated:</h3>
          <p><strong>{centreCode}</strong></p>
          <button onClick={() => setShowCentreCode(true)} className="complete-profile-submit-button">
            Continue
          </button>
        </div>
      )}
    </div>
  );
};

export default CompleteProfile;
