import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx'; // Import XLSX for handling Excel files
import '../styles/FormC.css';
import { ENDPOINT } from './config';

const FormC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    date: '',
    maternalAge: '',
    residenceArea: '',
    gaLMP: '',
    gaByCRL: '',
    firstSecondOpinion: '',
    singleMultiSystem: '',
    finalDiagnosis: '',
    systemsInvolved: '',
  });
  const [isExcelUploaded, setIsExcelUploaded] = useState(false); // Track if Excel is uploaded
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    if (!isExcelUploaded) { // Disable manual input if Excel is uploaded
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const headers = parsedData[0]; // First row as headers
      const values = parsedData[1]; // Second row as values

      const newFormData = {};
      headers.forEach((header, index) => {
        newFormData[header] = values[index];
      });

      console.log(newFormData);

      setFormData(newFormData);

      setIsExcelUploaded(true); // Mark Excel as uploaded
    };

    reader.readAsArrayBuffer(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(`${ENDPOINT}/api/formC`, formData);
      setSuccess(true);
      navigate('/success'); // Redirect after submission
    } catch (error) {
      console.error('Error submitting Form C:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-page">
      {loading && <div className="loading">Submitting...</div>}
      {success && <div className="success-popup">Form submitted successfully!</div>}
      {!loading && !success && (
        <>
          <h2>Submit Form C</h2>

          {/* Excel Upload Section */}
          <section className="upload-section">
            <h3>Upload Excel File</h3>
            <p>If you have the data in an Excel file, upload it here to automatically populate the form fields.</p>
            <div className="formC-group">
              <label>Upload Excel File (optional)</label>
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
              />
            </div>
            {isExcelUploaded && <p className="success-message">Excel file uploaded successfully. The form has been populated.</p>}
          </section>

          <div className="divider">OR</div> {/* Divider between sections */}

          {/* Manual Entry Section */}
          <section className="manual-entry-section formC-container">
            <h3>Manual Data Entry</h3>
            <p>Alternatively, you can fill in the details manually below.</p>

            <form onSubmit={handleSubmit}>
              <div className="formC-group">
                <label htmlFor="date">Date</label>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                  disabled={isExcelUploaded} // Disable if Excel is uploaded
                />
              </div>
              <div className="formC-group">
                <label htmlFor="maternalAge">Maternal Age</label>
                <input
                  type="number"
                  name="maternalAge"
                  value={formData.maternalAge}
                  onChange={handleChange}
                  required
                  disabled={isExcelUploaded} // Disable if Excel is uploaded
                />
              </div>
              <div className="formC-group">
                <label htmlFor="residenceArea">Residence Area</label>
                <input
                  type="text"
                  name="residenceArea"
                  value={formData.residenceArea}
                  onChange={handleChange}
                  required
                  disabled={isExcelUploaded} // Disable if Excel is uploaded
                />
              </div>
              <div className="formC-group">
                <label htmlFor="gaLMP">GA LMP</label>
                <input
                  type="text"
                  name="gaLMP"
                  value={formData.gaLMP}
                  onChange={handleChange}
                  required
                  disabled={isExcelUploaded} // Disable if Excel is uploaded
                />
              </div>
              <div className="formC-group">
                <label htmlFor="gaByCRL">GA by CRL</label>
                <input
                  type="text"
                  name="gaByCRL"
                  value={formData.gaByCRL}
                  onChange={handleChange}
                  required
                  disabled={isExcelUploaded} // Disable if Excel is uploaded
                />
              </div>
              <div className="formC-group">
                <label htmlFor="firstSecondOpinion">First/Second Opinion</label>
                <input
                  type="text"
                  name="firstSecondOpinion"
                  value={formData.firstSecondOpinion}
                  onChange={handleChange}
                  required
                  disabled={isExcelUploaded} // Disable if Excel is uploaded
                />
              </div>
              <div className="formC-group">
                <label htmlFor="singleMultiSystem">Single/Multi System</label>
                <input
                  type="text"
                  name="singleMultiSystem"
                  value={formData.singleMultiSystem}
                  onChange={handleChange}
                  required
                  disabled={isExcelUploaded} // Disable if Excel is uploaded
                />
              </div>
              <div className="formC-group">
                <label htmlFor="finalDiagnosis">Final Diagnosis</label>
                <input
                  type="text"
                  name="finalDiagnosis"
                  value={formData.finalDiagnosis}
                  onChange={handleChange}
                  required
                  disabled={isExcelUploaded} // Disable if Excel is uploaded
                />
              </div>
              <div className="formC-group">
                <label htmlFor="systemsInvolved">Systems Involved</label>
                <input
                  type="text"
                  name="systemsInvolved"
                  value={formData.systemsInvolved}
                  onChange={handleChange}
                  required
                  disabled={isExcelUploaded} // Disable if Excel is uploaded
                />
              </div>

              <button type="submit" className="submit-button">Submit</button>
            </form>
          </section>
        </>
      )}
    </div>
  );
};

export default FormC;
