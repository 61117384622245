
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/AdminPage.css';
import { ENDPOINT } from './config';

const AdminPage = ({ formType }) => {
  const [unvalidatedData, setUnvalidatedData] = useState([]);

  useEffect(() => {
    const fetchUnvalidatedData = async () => {
      try {
        const response = await axios.get(`${ENDPOINT}/api/${formType}/unvalidated`);
        setUnvalidatedData(response.data);
      } catch (error) {
        console.error('Error fetching unvalidated data:', error);
      }
    };

    fetchUnvalidatedData();
  }, [formType]);

  const handleValidate = async (id) => {
    try {
      await axios.patch(`${ENDPOINT}/api/${formType}/validate/${id}`);
      setUnvalidatedData(prevData => prevData.filter(data => data._id !== id));
    } catch (error) {
      console.error('Error validating data:', error);
    }
  };

  const renderDataFields = (data) => {
    switch (formType) {
      case 'formA':
        return (
          <>
            <p>Report Month Year: {new Date(data.reportMonthYear).toLocaleDateString()}</p>
            <p>Live Birth: {data.liveBirth}</p>
            <p>IUD Still Birth: {data.iudStillBirth}</p>
            <p>MTP Following Anomaly: {data.mtpFollowingAnomaly}</p>
          </>
        );
      case 'formB':
        return (
          <>
            <div className="section-header">Section A</div>
            <div className="section-content">
              <p><strong>Date of Birth:</strong> {new Date(data.sectionA.dateOfBirth).toLocaleDateString()}</p>
              <p><strong>Report Month Year:</strong> {data.sectionA.reportMonthYear}</p>
              <p><strong>Birth Weight:</strong> {data.sectionA.birthWeight}</p>
              <p><strong>Gestational Age:</strong> {data.sectionA.gestationalAge}</p>
              <p><strong>Baby Delivered As:</strong> {data.sectionA.babyDeliveredAs}</p>
              <p><strong>Last Menstrual Period:</strong> {new Date(data.sectionA.lastMenstrualPeriod).toLocaleDateString()}</p>
              <p><strong>Birth Type:</strong> {data.sectionA.birthType}</p>
            </div>

            <div className="section-header">Section B</div>
            <div className="section-content">
              <p><strong>Mother's Name:</strong> {data.sectionB.motherName}</p>
              <p><strong>Mother's Age:</strong> {data.sectionB.motherAge}</p>
              <p><strong>Father's Name:</strong> {data.sectionB.fatherName}</p>
              <p><strong>Father's Age:</strong> {data.sectionB.fatherAge}</p>
              <p><strong>House Name:</strong> {data.sectionB.houseName}</p>
              <p><strong>Area:</strong> {data.sectionB.area}</p>
              <p><strong>City:</strong> {data.sectionB.city}</p>
              <p><strong>State:</strong> {data.sectionB.state}</p>
              <p><strong>Pincode:</strong> {data.sectionB.pincode}</p>
              <p><strong>Street:</strong> {data.sectionB.street}</p>
              <p><strong>Contact Number:</strong> {data.sectionB.contactNumber}</p>
              <p><strong>Email:</strong> {data.sectionB.email}</p>
            </div>

            <div className="section-header">Section C</div>
            <div className="section-content">
              <p><strong>Anomaly:</strong> {data.sectionC.anomaly}</p>
              <p><strong>Notifying Person:</strong> {data.sectionC.notifyingPerson}</p>
              <p><strong>Designation:</strong> {data.sectionC.designation}</p>
              <p><strong>Diagnosis:</strong> {data.sectionC.diagnosis}</p>
            </div>

            <div className="section-header">Section D</div>
              <div className="section-content">
                <p><strong>Final Diagnosis:</strong> {data.sectionD.finalDiagnosis}</p>
                <p><strong>ICD Code:</strong> {data.sectionD.icdCode}</p>
                
                {data.sectionD.document && data.sectionD.document.data && (
                  <p><strong>Document:</strong> 
                    <a
                      href={`data:${data.sectionD.document.contentType};base64,${btoa(String.fromCharCode(...new Uint8Array(data.sectionD.document.data)))}`}
                      download={data.sectionD.document.filename}
                    >
                      Download
                    </a>
                  </p>
                )}
              </div>



          </>
        );
      case 'formC':
        return (
          <>
            <p>Date: {new Date(data.date).toLocaleDateString()}</p>
            <p>Maternal Age: {data.maternalAge}</p>
            <p>Residence Area: {data.residenceArea}</p>
            <p>GA by LMP: {data.gaLMP}</p>
            <p>GA by CRL: {data.gaByCRL}</p>
            <p>First/Second Opinion: {data.firstSecondOpinion}</p>
            <p>Final Diagnosis: {data.finalDiagnosis}</p>
          </>
        );
      default:
        return <p>No data available.</p>;
    }
  };

  return (
    <div className="admin-page">
      <h2>Admin Page - Validate {formType.toUpperCase()}</h2>
      {unvalidatedData.length === 0 ? (
        <p>No unvalidated data available.</p>
      ) : (
        unvalidatedData.map(data => (
          <div key={data._id} className="data-item">
            <h3>{data.centreName}</h3>
            {renderDataFields(data)}
            <button onClick={() => handleValidate(data._id)}>Validate</button>
          </div>
        ))
      )}
    </div>
  );
};

export default AdminPage;
