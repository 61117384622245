import './styles/App.css';
import { useAuth0 } from '@auth0/auth0-react';
import Home from './Components/Home';
import Forms from './Components/Forms';
import Footer from './Components/Footer';
import FormA from './Components/FormA';
import FormB from './Components/FormB';
import FormC from './Components/FormC';
import SectionA from './Components/SectionA';
import Hotspot from './Components/Hotspot';
import Dashboard from './Components/Dashboard';
import AdminDashboard from './Components/AdminDashboard';
import CompleteProfile from './Components/CompleteProfile';
import AdminPage from './Components/AdminPage';
import Newsletters from './Components/Newsletters';
import Nav from './Components/Nav';
import ContactUs from './Components/ContactUs';
import { RingLoader } from 'react-spinners';
import About from './Components/About';
import ValidateUsersPage from './Components/ValidateUsersPage';



import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

function App() {
    const { isLoading, isAuthenticated } = useAuth0();
    if (isLoading) {
      return (
        <div className="loader">
          <RingLoader color={'#1702f7'} loading={isLoading} size={150} />
        </div>
      );
    }


  return (
     <BrowserRouter>
      <div className="App">
        <Nav/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/forms" element={<Forms/>} />
          <Route path="/formA" element={<FormA/>}/>
          <Route path="/formB" element={<FormB/>}/>
          <Route path="/formC" element={<FormC/>}/>
          <Route path="/sectionA" element={<SectionA/>}/>
          <Route path="/Dashboard" element={<Dashboard/>}/>
          <Route path="/newsletters" element={<Newsletters/>} />
          <Route path="/complete-profile" element={<CompleteProfile/>}/>
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/admin/validate/formA" element={<AdminPage formType="formA" />} />
          <Route path="/admin/validate/formB" element={<AdminPage formType="formB" />} />
          <Route path="/admin/validate/formC" element={<AdminPage formType="formC" />} />
          <Route path="/about" element={<About/>}/>
          <Route path="/hotspot" element={<Hotspot/>}/>
          <Route path="/admin/validate-users" element={<ValidateUsersPage />} />
          <Route path="/contact" element={<ContactUs />} />

          {/* <Route path="/summarizer" element = {isAuthenticated ? <Summarizer /> : <Navigate to="/"/>} /> */}
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
