import React from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import '../styles/ContactUs.css';

const ContactUs = () => {
  return (
    <Container maxWidth="md" className="contact-us-container">
      <Typography variant="h4" className="contact-title" align="center" gutterBottom>
        Contact Us
      </Typography>
      
      <section className="contact-info-section">
        <Typography variant="h5" className="info-title">Contact Information</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box className="contact-detail">
              <Typography variant="body1"><strong>Location:</strong></Typography>
              <Typography variant="body2">No 18, Besant Road, Royapettah,<br />Chennai, Tamil Nadu, India</Typography>
            </Box>

            <Box className="contact-detail">
              <Typography variant="body1"><strong>Phone:</strong></Typography>
              <Typography variant="body2">+91 93802 22773</Typography>
            </Box>

            <Box className="contact-detail">
              <Typography variant="body1"><strong>Email:</strong></Typography>
              <Typography variant="body2">support@bdri.org</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box className="contact-detail">
              <Typography variant="body1"><strong>Person Responsible:</strong></Typography>
              <Typography variant="body2">Mrs. Jayashree Balachandran</Typography>
            </Box>

            <Box className="contact-detail">
              <Typography variant="body1"><strong>Office Hours:</strong></Typography>
              <Typography variant="body2">Mon - Fri, 9 AM - 5 PM</Typography>
            </Box>

            <Box className="contact-detail">
              <Typography variant="body1"><strong>Social Media:</strong></Typography>
              <Typography variant="body2">
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a> | 
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"> Twitter</a>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </section>
    </Container>
  );
};

export default ContactUs;
